export const useFetchMeta = async (nuxtApp, runtimeConfig,  ) => {
	
	const { $country, $router } = nuxtApp; // You can get this from nuxtApp
	const metaObj = ref({});
	const title = ref('Some Page'); // Default title
	const route = $router.currentRoute.value;
	const localPath = useLocalePath()

	let path = route.path;



	// Adjust path for home and specific country paths
	if (path === '/') {
		path = '/home';
	}
	if (path === '/in') {
		path = '/in/home';
	}

	try {
		// Use the `useFetch` composable to fetch the meta data
		const { data, error } = await useFetch(useCountryPath('/config/meta'), {
			params: { path },
		});

		if (error.value) {
			console.error('Error fetching meta data:', error.value);
			return;
		}

		if(!data || !data?.value?.data  ){

			return
		}

		const obj = data.value.data;
		// const canonicalPath = route.path
		// let finalUrl = canonicalPath;

		// // Adjust URL based on country
		// if ($country.location === 'US') {
		// 	finalUrl = canonicalPath.replace(runtimeConfig.public.domain, runtimeConfig.public.domain + '/in');
		// } else if ($country.location === 'IN') {
		// 	finalUrl = canonicalPath.replace(runtimeConfig.public.domain + '/in', runtimeConfig.public.domain);
		// }

		// Set meta data object
		title.value = obj.meta_title;

		const excludeAlternateLinks = ["valentines-day-jewelry___in", "franchise___us", "customer-service-exchange-buyback-policy___us"]
        const routeName = extractRouteName(route.name)


		const alternateUrls = ['us', 'in'].map( (cv) => {
			let url = `${routeName}___${cv}`
			if(excludeAlternateLinks?.includes(url)){
                return null
            }

			const path = getFullUrl( localPath( {name:extractRouteName(route.name)}, cv ) )
			return {
				rel: 'alternate',
				hreflang: `en-${cv.toUpperCase()}`,
				href:path
			}
		}).filter((item)=> item)

		const canonicalPath  = { rel: 'canonical', href: getFullUrl( localPath( {name:extractRouteName(route.name)} ) ) }


		metaObj.value = {
			title: obj.meta_title,
			meta: [
			{ name: 'description', content: obj.meta_description },
			{ name: 'keywords', content: obj.meta_keyword },
			{ property: 'og:title', content: obj.meta_title },
			{ property: 'og:type', content: 'website' },
			{ property: 'og:url', content: canonicalPath },
			{ property: 'og:description', content: obj.meta_description },
			{ property: 'og:site_name', content: 'Friendly Diamonds' },
			{ property: 'og:image', content: '' },
			{ property: 'twitter:title', content: obj.meta_title },
			],
			link: [
				...alternateUrls, 
				canonicalPath
			// { rel: 'canonical', href: canonicalPath },
			// { rel: 'alternate', hreflang: $country.language, href: finalUrl },
			// { rel: 'alternate', hreflang: `en-${$country.location}`, href: canonicalPath },
			],
		};

		// Apply meta tags using useHead
		useHead(metaObj.value);
	} catch (err) {
		console.error('Error fetching meta data:', err);
	}
  };
  